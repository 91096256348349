<template>
  <div>
    <b-row
      >
      <b-col cols="12" lg="12">
        <ek-table
          :items="guideDetailsList"
          :columns="DetailsGuideCols"
          deleteBtn
          @details="goToDetailsInvoice"
          no_delete
        >
          <template slot="items.date" slot-scope="{ value }">
            {{ value ? new Date(value).toLocaleDateString() : "" }}
          </template>
        </ek-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      guidesDetails: ({ invoiceGuide }) => invoiceGuide.guidesDetails,
      DetailsGuideCols: ({ invoiceGuide }) => invoiceGuide.DetailsGuideCols,
    }),
    ...mapGetters(["guideDetailsList"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getDetailsGuideList"]),
    goToDetailsInvoice({ row }) {
      this.$router.push({
        path: `/admin/invoice/invoiceGuide/${this.id}/${row.id}`,
      });
    },
  },
  created() {
    this.getDetailsGuideList(this.id);
  },
};
</script>